import React, { useContext } from 'react';
import BottomNavigationItem from '../molecules/BottomNavigationItem';
import STBottomNav from "../../assets/styles/organisms/footer.module.scss";
import { SiteContext } from '../../providers/SiteProvider';

const BottomNavigationBar = () => {
    const site = useContext(SiteContext);
    const mainColor = site.mainColor;
    const isChat = site.isChat;
    const menuList = [
        {
            url: '/',
            icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                    <mask id="mask0_383_3408" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="20">
                    <rect width="24" height="20" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_383_3408)">
                    <path d="M20.75 20H14.917C14.503 20 14.167 19.664 14.167 19.25V13H9.834V19.25C9.834 19.664 9.498 20 9.084 20H3.25C2.836 20 2.5 19.664 2.5 19.25V6.30001C2.5 6.04301 2.631 5.80401 2.848 5.66701L11.598 0.116006C11.843 -0.0389941 12.157 -0.0389941 12.402 0.116006L21.152 5.66601C21.369 5.80401 21.5 6.04201 21.5 6.29901V19.249C21.5 19.663 21.164 20 20.75 20ZM15.667 18.5H20V6.71201L12 1.63801L4 6.71201V18.5H8.333V12.25C8.333 11.836 8.669 11.5 9.083 11.5H14.916C15.33 11.5 15.666 11.836 15.666 12.25L15.667 18.5Z" fill="#222222"/>
                    </g>
                </svg>,
            activeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                            <mask id="mask0_383_3403" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="20">
                            <rect width="24" height="20" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_383_3403)">
                            <path d="M21.152 5.66601L12.402 0.116006C12.157 -0.0389941 11.843 -0.0389941 11.598 0.116006L2.848 5.66601C2.631 5.80401 2.5 6.04201 2.5 6.29901V19.249C2.5 19.663 2.836 19.999 3.25 19.999H9.083C9.497 19.999 9.833 19.663 9.833 19.249V12.999H14.166V19.249C14.166 19.663 14.502 19.999 14.916 19.999H20.749C21.163 19.999 21.499 19.663 21.499 19.249V6.30001C21.499 6.04301 21.368 5.80401 21.151 5.66701L21.152 5.66601Z" fill={mainColor}/>
                            </g>
                        </svg>,
            text: 'ホーム'
        },
        {
            url: '/informations',
            icon:<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                    <path d="M17.491 13.297C17.492 13.281 17.5 13.266 17.5 13.25V0.75C17.5 0.336 17.164 0 16.75 0H1.25C0.836 0 0.5 0.336 0.5 0.75V19.25C0.5 19.664 0.836 20 1.25 20H10.75C10.848 20 10.945 19.98 11.036 19.943C11.127 19.906 11.21 19.851 11.281 19.78L17.281 13.78C17.345 13.716 17.391 13.64 17.428 13.56C17.439 13.537 17.445 13.513 17.453 13.489C17.474 13.426 17.487 13.363 17.491 13.296V13.297ZM2 1.5H16V12.5H10.75C10.336 12.5 10 12.836 10 13.25V18.5H2V1.5Z" fill="#222222"/>
                    <path d="M6.09375 6.5H11.9058C12.3198 6.5 12.6558 6.164 12.6558 5.75C12.6558 5.336 12.3198 5 11.9058 5H6.09375C5.67975 5 5.34375 5.336 5.34375 5.75C5.34375 6.164 5.67975 6.5 6.09375 6.5Z" fill="#222222"/>
                    <path d="M11.9058 10.5C12.3198 10.5 12.6558 10.164 12.6558 9.75C12.6558 9.336 12.3198 9 11.9058 9H6.09375C5.67975 9 5.34375 9.336 5.34375 9.75C5.34375 10.164 5.67975 10.5 6.09375 10.5H11.9058Z" fill="#222222"/>
                </svg>,
            activeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                            <mask id="mask0_383_3402" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="20">
                            <rect width="24" height="20" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_383_3402)">
                            <path d="M19.75 0H4.25C3.836 0 3.5 0.336 3.5 0.75V19.25C3.5 19.664 3.836 20 4.25 20H13.75C13.848 20 13.945 19.98 14.036 19.943C14.127 19.906 14.21 19.851 14.281 19.78L20.281 13.78C20.345 13.716 20.391 13.64 20.428 13.56C20.439 13.537 20.445 13.513 20.453 13.489C20.474 13.426 20.487 13.363 20.491 13.296C20.492 13.28 20.5 13.265 20.5 13.249V0.75C20.5 0.336 20.164 0 19.75 0ZM9.094 5H14.906C15.32 5 15.656 5.336 15.656 5.75C15.656 6.164 15.32 6.5 14.906 6.5H9.094C8.68 6.5 8.344 6.164 8.344 5.75C8.344 5.336 8.68 5 9.094 5ZM9.094 10.5C8.68 10.5 8.344 10.164 8.344 9.75C8.344 9.336 8.68 9 9.094 9H14.906C15.32 9 15.656 9.336 15.656 9.75C15.656 10.164 15.32 10.5 14.906 10.5H9.094ZM14.5 17.439V14H17.939L14.5 17.439Z" fill={mainColor}/>
                            </g>
                        </svg>,
            text: 'お役立ち記事'
        },
        {
            url: '/point_request',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
                    <path d="M10 20C4.486 20 0 15.514 0 10C0 4.486 4.486 0 10 0C15.514 0 20 4.486 20 10C20 15.514 15.514 20 10 20ZM10 1.5C5.313 1.5 1.5 5.313 1.5 10C1.5 14.687 5.313 18.5 10 18.5C14.687 18.5 18.5 14.687 18.5 10C18.5 5.313 14.687 1.5 10 1.5Z" fill="#222222"/>
                    <path d="M10.476 5.7019H8.26502C7.85102 5.7019 7.51501 6.0379 7.51501 6.4519V13.5489C7.51501 13.9629 7.85102 14.2989 8.26502 14.2989C8.67902 14.2989 9.01502 13.9629 9.01502 13.5489V11.6589H10.476C12.118 11.6589 13.454 10.3229 13.454 8.6809C13.454 7.0389 12.118 5.7019 10.476 5.7019ZM10.476 10.1579H9.01502V7.2009H10.476C11.291 7.2009 11.954 7.8639 11.954 8.6799C11.954 9.4959 11.291 10.1579 10.476 10.1579Z" fill="#222222"/>
                </svg>,
            activeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill={mainColor}>
                            <path d="M10.4761 7.2019H9.01514V10.1589H10.4761C11.2911 10.1589 11.9541 9.4959 11.9541 8.6809C11.9541 7.8659 11.2911 7.2019 10.4761 7.2019Z" fill={mainColor}/>
                            <path d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM10.476 11.658H9.015V13.548C9.015 13.962 8.679 14.298 8.265 14.298C7.851 14.298 7.515 13.962 7.515 13.548V6.451C7.515 6.037 7.851 5.701 8.265 5.701H10.476C12.118 5.701 13.454 7.037 13.454 8.68C13.454 10.323 12.118 11.658 10.476 11.658Z" fill={mainColor}/>
                        </svg>,
            text: 'ポイント申請'
        },
        {
            url: !isChat ? '/contact' : '/chat',
            icon: !isChat ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
                    <path d="M19.75 17.5H2.25C1.285 17.5 0.5 16.715 0.5 15.75V2.25C0.5 1.285 1.285 0.5 2.25 0.5H19.75C20.715 0.5 21.5 1.285 21.5 2.25V15.75C21.5 16.715 20.715 17.5 19.75 17.5ZM2.25 2C2.112 2 2 2.112 2 2.25V15.75C2 15.888 2.112 16 2.25 16H19.75C19.888 16 20 15.888 20 15.75V2.25C20 2.112 19.888 2 19.75 2H2.25Z" fill="#222222"/>
                    <path d="M11 10.7751C10.31 10.7751 9.62 10.5131 9.083 9.99006L4.34 5.37706C4.043 5.08806 4.036 4.61306 4.325 4.31606C4.614 4.01906 5.089 4.01306 5.386 4.30106L10.129 8.91406C10.617 9.39006 11.383 9.39006 11.872 8.91406L16.615 4.30106C16.913 4.01306 17.387 4.01906 17.676 4.31606C17.965 4.61306 17.958 5.08806 17.661 5.37706L12.918 9.99006C12.38 10.5131 11.69 10.7751 11.001 10.7751H11Z" fill="#222222"/>
                </svg> :
                <svg width="32" height="26" viewBox="0 0 22 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
                    <path fill-rule="evenodd" d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"/>
                </svg>,
            activeIcon: !isChat ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
                    <path d="M19.75 0.5H2.25C1.285 0.5 0.5 1.285 0.5 2.25V15.75C0.5 16.715 1.285 17.5 2.25 17.5H19.75C20.715 17.5 21.5 16.715 21.5 15.75V2.25C21.5 1.285 20.715 0.5 19.75 0.5ZM17.66 5.377L12.917 9.99C12.379 10.513 11.689 10.774 11 10.774C10.311 10.774 9.62 10.513 9.083 9.99L4.34 5.377C4.043 5.088 4.036 4.613 4.325 4.316C4.614 4.019 5.089 4.013 5.386 4.301L10.129 8.914C10.617 9.389 11.384 9.389 11.872 8.914L16.615 4.301C16.913 4.013 17.387 4.019 17.676 4.316C17.965 4.613 17.957 5.088 17.66 5.377Z" fill={mainColor}/>
                </svg> :
                <svg width="32" height="26" viewBox="0 0 22 19" fill={mainColor} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
                    <path fill-rule="evenodd" d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"/>
                </svg>,
            text: !isChat ? 'お問い合わせ' : 'チャット'
        },{
            url: '/mypage',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
                    <path d="M10 20C4.486 20 0 15.514 0 10C0 4.486 4.486 0 10 0C15.514 0 20 4.486 20 10C20 15.514 15.514 20 10 20ZM10 1.5C5.313 1.5 1.5 5.313 1.5 10C1.5 14.687 5.313 18.5 10 18.5C14.687 18.5 18.5 14.687 18.5 10C18.5 5.313 14.687 1.5 10 1.5Z" fill="#222222"/>
                    <path d="M4.925 14.648C4.74 14.648 4.555 14.58 4.41 14.443C4.109 14.159 4.095 13.684 4.379 13.383C5.048 12.673 5.839 12.101 6.711 11.69C5.922 10.84 5.465 9.70803 5.465 8.50603C5.465 5.94003 7.499 3.85303 10 3.85303C12.501 3.85303 14.535 5.94003 14.535 8.50603C14.535 9.70703 14.077 10.84 13.289 11.69C14.16 12.101 14.951 12.673 15.621 13.383C15.905 13.685 15.891 14.159 15.59 14.444C15.288 14.728 14.814 14.714 14.529 14.413C13.727 13.562 12.709 12.956 11.584 12.661C11.287 12.583 11.068 12.332 11.03 12.028C10.992 11.724 11.143 11.427 11.412 11.279C12.413 10.727 13.036 9.66503 13.036 8.50803C13.036 6.76903 11.675 5.35503 10.001 5.35503C8.327 5.35503 6.966 6.77003 6.966 8.50803C6.966 9.66503 7.588 10.727 8.589 11.279C8.858 11.427 9.009 11.724 8.971 12.028C8.933 12.332 8.714 12.583 8.418 12.661C7.293 12.956 6.274 13.562 5.472 14.413C5.325 14.569 5.126 14.648 4.927 14.648H4.925Z" fill="#222222"/>
                </svg>,
            activeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20" fill="none">
                            <path d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM15.589 14.445C15.287 14.728 14.813 14.715 14.528 14.414C13.726 13.563 12.708 12.957 11.582 12.661C11.286 12.583 11.066 12.332 11.029 12.028C10.991 11.724 11.142 11.427 11.411 11.279C12.412 10.727 13.035 9.665 13.035 8.508C13.035 6.769 11.674 5.355 10 5.355C8.326 5.355 6.965 6.77 6.965 8.508C6.965 9.665 7.587 10.727 8.588 11.279C8.857 11.427 9.008 11.724 8.97 12.028C8.932 12.332 8.713 12.583 8.417 12.661C7.292 12.957 6.273 13.564 5.471 14.414C5.324 14.57 5.125 14.649 4.926 14.649C4.741 14.649 4.556 14.581 4.411 14.444C4.11 14.16 4.096 13.685 4.38 13.384C5.049 12.675 5.84 12.102 6.712 11.691C5.923 10.841 5.465 9.709 5.465 8.507C5.465 5.941 7.499 3.854 10 3.854C12.501 3.854 14.535 5.941 14.535 8.507C14.535 9.709 14.077 10.841 13.288 11.691C14.16 12.102 14.951 12.674 15.62 13.384C15.904 13.686 15.89 14.16 15.589 14.445Z" fill={mainColor}/>
                        </svg>,
            text: 'マイページ'
        }
    ]
    return (
        <div className={STBottomNav.bottom_bar_container}>
            <div className={STBottomNav.bottom_bar}>
                {menuList.map((menu , index) => {
                    return <BottomNavigationItem key={index} {...menu} index={index} />
                })}
            </div>
        </div>
    )
}

export default BottomNavigationBar;
