import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { useSendbirdStateContext } from "@sendbird/uikit-react/SendbirdProvider";
import '@sendbird/uikit-react/dist/index.css';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import GroupChannelHandler from "@sendbird/uikit-react/handlers/GroupChannelHandler";

export const UnreadMessageContext = createContext({
    unreadCount: 0,
    updateUnreadCount: (channelUrl: string, count: number) => {}
});

export const ChatProvider = (props: { children: ReactNode }) => {
    const { children } = props;
    const [unreadCounts, setUnreadCounts] = useState(new Map());
    const sendbirdContext = useSendbirdStateContext();
    const sdk = sendbirdSelectors.getSdk(sendbirdContext);

    useEffect(() => {
        if (!sdk || !sdk.groupChannel) return;
        const fetchChannels = async () => {
            const listQuery =  sdk.groupChannel.createMyGroupChannelListQuery();
            const channelList = await listQuery.next();
            channelList.forEach((channel : any) => {
                updateUnreadCount(channel.url, channel.unreadMessageCount);
            })
        };
        fetchChannels();
    }, [sdk]);

    

    useEffect(() => {
        if(!sdk || !sdk.groupChannel) return;
        const UNIQUE_HANDLER_UD = 'uniqueid';
        const groupChannelHandler = new GroupChannelHandler();
        sdk.groupChannel.addGroupChannelHandler(UNIQUE_HANDLER_UD, groupChannelHandler);
        groupChannelHandler.onMessageReceived = (channel: any, message) => {
            updateUnreadCount(channel.url, channel.unreadMessageCount);
        }
        return () => {
            sdk?.groupChannel?.removeGroupChannelHandler(UNIQUE_HANDLER_UD);
        };
    }, [sdk])

    const updateUnreadCount = (channelUrl: string, count: number) => {
        setUnreadCounts(preCounts => {
            const newCounts = new Map(preCounts);
            if(count > 0){
                newCounts.set(channelUrl, count);
            } else{
                newCounts.delete(channelUrl);
            }
            return newCounts;
        })
    }

    const totalUnreadCount = Array.from(unreadCounts.values()).reduce((a,b) => a + b, 0);

    return (
        <UnreadMessageContext.Provider value={{ unreadCount: totalUnreadCount , updateUnreadCount}}>
            {children}
        </UnreadMessageContext.Provider>
    )
}