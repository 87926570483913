import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import PreviewFile from '../molecules/PreviewFile';
import STComfirmFilesMessage from "../../assets/styles/organisms/comfirmFilesMessage.module.scss";

type ComfirmFilesMessageModalProps = {
    open: boolean;
    handleClose: any;
    handleAgree: any;
    fileMessageParams: any;
    setFileMessageParams: any;
}

const ComfirmFilesMessageModal = ({open , handleClose, handleAgree, fileMessageParams, setFileMessageParams}: ComfirmFilesMessageModalProps) => {

    const handleRemoveFiles = (index: number) => {
        if(fileMessageParams?.fileInfoList){
            if(fileMessageParams?.fileInfoList.length === 1){
                handleClose();
                return;
            }
            const updatedinforList = [...fileMessageParams?.fileInfoList];
            updatedinforList.splice(index , 1);
            setFileMessageParams((prevState: any) => ({
                ...prevState,
                fileInfoList: updatedinforList
            }))
        } else{
            handleClose();
        }
    }

    return (
        <Dialog
            className={STComfirmFilesMessage.dialog}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className={STComfirmFilesMessage.dialog_header} id="alert-dialog-title">
                <div className={STComfirmFilesMessage.dialog_header_container}>
                    <h4>このチャットルームにファイルを送信しますか ?</h4>
                    <div className={STComfirmFilesMessage.header_close_button} onClick={handleClose}>
                        <img src='./close_icon.svg' />
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className={STComfirmFilesMessage.list_file} id="alert-dialog-description">
                {fileMessageParams?.fileInfoList ? 
                    fileMessageParams.fileInfoList.map((file: any, index: number) => (
                        <PreviewFile handleRemoveFiles={() => handleRemoveFiles(index)} fileMessageParams={file} />
                    ))
                    : <PreviewFile handleRemoveFiles={() => handleRemoveFiles(0)} fileMessageParams={fileMessageParams} />
                }
            </DialogContentText>
            </DialogContent>
            <DialogActions className={STComfirmFilesMessage.dialog_footer_container}>
                <button className={STComfirmFilesMessage.close_button} onClick={handleClose}>キャンセル</button>
                <button className={STComfirmFilesMessage.send_button} onClick={handleAgree}>
                    送信
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default ComfirmFilesMessageModal;
