import React from 'react';
import { Box } from '@material-ui/core';

const formatChatDate = (date: number): string => {
  const t = new Date(date);
  const y = t.getFullYear().toString();
  const m = (t.getMonth() + 1).toString().padStart(2, '0');
  const d = t.getDate().toString().padStart(2, '0');
  return `${y}/${m}/${d}`;
};

interface Props {
  timestamp: number;
}

const ChatCustomSeparator = ({ timestamp }: Props) => (
  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'cebter' }}>
    <p
      style={{
        margin: '11px 0 15px',
        padding: '5px 15px',
        backgroundColor: '#C4C4C4',
        borderRadius: '20px',
        fontSize: '14px',
        color: '#fff',
      }}
    >
      {formatChatDate(timestamp)}
    </p>
  </Box>
);

export default ChatCustomSeparator;
