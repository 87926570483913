import Drawer from "@material-ui/core/Drawer";
import STDrawerMenu from "../../assets/styles/organisms/drawerMenu.module.scss";
import STSearchForm from "../../assets/styles/molecules/searchForm.module.scss";
import STMemberInfoCard from "../../assets/styles/organisms/memberInfoCard.module.scss";
import PAGE_CONFIG from "../../config/page";
import { routes } from "../../router/Router";
import { NaviItem } from "../../types/naviItem";
import DrawerMenuButton from "../atoms/DrawerMenuButton";
import Logo from "../molecules/ImageLink";
import NaviMenu from "../molecules/NaviMenu";
import { Link, useHistory } from "react-router-dom";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import Text from "../atoms/form/Text";
import Button from "../atoms/Button";
import { useContext, useState } from "react";
import { SiteContext } from "../../providers/SiteProvider";
import SvgSearch from "../atoms/icons/Search";
import CONSTANTS from "../../utils/constants";
import ButtonLink from "../atoms/ButtonLink";
import MemberInfoCardNew from "./MemberInforCardNew";

const useStyles = makeStyles(() => ({
  myPage: {
    fontWeight: 700,
    fontSize: "14px",
  },
}));

type Props = {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  subdomain: string;
  imageUrl: string;
  naviItems: NaviItem[];
  showArrowIcon: boolean | undefined
};

const DrawerMenu: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const hundleClick = () => {
    props.setIsOpen(!props.isOpen);
  };

  const site = useContext(SiteContext);
  const isChat = site?.isChat;
  const newUiVersion = site.newUIVersion;
  const [query , setQuery] = useState<string>('');

  const handleChange = (value: string) => {
    if (value && value.length > CONSTANTS.MAX_LENGTH.SEARCH_FORM_TEXT) {
      setQuery(value.substring(0, CONSTANTS.MAX_LENGTH.SEARCH_FORM_TEXT));
      return;
    }
    setQuery(value);
  }

  const handleSearch = () => {
    hundleClick()
    history.push(`${routes.informations.path}?title=${query}`);
  }

  return (
    <Drawer
      anchor="right"
      open={props.isOpen}
      onClose={hundleClick}
      classes={{ paper: STDrawerMenu.drawer_menu }}
    >
      <div className={!newUiVersion ? STDrawerMenu.header : STDrawerMenu.header_new}>
        {!newUiVersion ? 
          <Logo
            linkUrl={routes.top.path}
            imageUrl={props.imageUrl}
            className={STDrawerMenu.logo}
            alt={props.subdomain}
          />
          :
          <div style={{display: "flex", alignItems: "center"}}>
            <Logo
              linkUrl={routes.top.path}
              imageUrl={props.imageUrl}
              className={STDrawerMenu.logo_new}
              alt={props.subdomain}
            />
          </div>
        }
        <DrawerMenuButton
          isOpen={props.isOpen}
          setIsOpen={hundleClick}
          subdomain={props.subdomain}
        />
      </div>
      <div className={STDrawerMenu.navi}>
        <NaviMenu naviItems={props.naviItems} showArrowIcon = {true} className="" />
          {newUiVersion && 
            <>
              <div className={STSearchForm.form_navigation}>
                <Text
                    className={STSearchForm.input_new}
                    name='q'
                    placeholder='サイト内検索'
                    onChange={handleChange}
                  />
                <Button
                  className={`${STSearchForm.button_new} ${PAGE_CONFIG.STATIC_CLASSES.NAVI_ICON}`}
                  buttonClassName={STSearchForm.button_inner}
                  clickAction={handleSearch}
                  text={
                    <>
                      <SvgSearch/>
                    </>
                  }
                />
              </div>
              <ButtonLink
                url={isChat ? routes.chat.path : routes.contact.path}
                text={isChat ? 'チャット' : 'お問い合わせ'}
                buttonClassName={`${STDrawerMenu.member_point_request_btn_header}`}
                linkClassName={STMemberInfoCard.member_point_request_link}
              />
              <MemberInfoCardNew inHeader={true} />
            </>
          }
      </div>
    </Drawer>
  );
};

export default DrawerMenu;
