import { useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Beginner from "../components/pages/Beginner";
import Contact from "../components/pages/Contact";
import Error from "../components/pages/Error";
import EventInvitation from "../components/pages/EventInvitation";
import Faq from "../components/pages/Faq";
import Information from "../components/pages/Information";
import Video from "../components/pages/Video";
import InformationList from "../components/pages/InformationList";
import ContentPreview from "../components/pages/ContentPreview";
import Login from "../components/pages/Login";
import MailAddressConfirm from "../components/pages/MailAddressConfirm";
import MemberEdit from "../components/pages/MemberEdit";
import MemberEntry from "../components/pages/MemberEntry";
import MemberPremium from "../components/pages/MemberPremium";
import News from "../components/pages/News";
import PointHistory from "../components/pages/PointHistory";
import PointRequest from "../components/pages/PointRequest";
import Privacy from "../components/pages/Privacy";
import ResetPassword from "../components/pages/ResetPassword";
import ResetPasswordMailAddressConfirm from "../components/pages/ResetPasswordMailAddressConfirm";
import StaticError from "../components/pages/StaticError";
import Term from "../components/pages/Term";
import Top from "../components/pages/Top";
import { LoginUserContext } from "../providers/LoginUserProvider";
import ContentFixedPreview from "../components/pages/ContentFixedPreview";
import usePageTracking from "../utils/useTracking";
import Referral from "../components/pages/Referral";
import Survey from "../components/pages/Survey";

import SurveyKodate from "../components/pages/SurveyKodate";
import SurveyMansion from "../components/pages/SurveyMansion";
import StaticDetail from "../components/pages/StaticDetail";
import { SiteContext } from "../providers/SiteProvider";
import useUserSession from "../hooks/useUserSession";
import { MyPage } from "../components/pages/MyPage";
import { FilesPage } from "../components/pages/Files";
import { FolderPage } from "../components/pages/Folder";
import NewsList from "../components/pages/NewsList";
import VideoList from "../components/pages/VideoList";
import MemberIdentification from "../components/pages/MemberIdentification";
import TopPreview from "../components/pages/TopPreview";
import MemberEntryNomal from "../components/pages/MemberEntryNomal";
import Chat from "../components/pages/Chat";
import ChatOff from "../components/pages/ChatOff";

export const routes = {
  top: {
    title: "トップページ",
    router: "/",
    path: "/",
    isPrivate: true,
    component: <Top />,
  },
  newsDetail: {
    router: "/news/:id",
    path: (id: string) => `/news/${id}`,
    isPrivate: true,
    component: <News />,
  },
  news: {
    title: "お知らせ・記事一覧",
    router: "/news",
    path: "/news",
    isPrivate: true,
    component: <NewsList />,
  },
  videos: {
    title: "お知らせ・記事一覧",
    router: "/videos",
    path: "/videos",
    isPrivate: true,
    component: <VideoList />,
  },
  informationDetail: {
    router: "/information/:id",
    path: (id: string) => `/information/${id}`,
    isPrivate: true,
    component: <Information />,
  },
  videoDetail: {
    router: "/video/:id",
    path: (id: string) => `/video/${id}`,
    isPrivate: true,
    component: <Video />,
  },
  informations: {
    title: "お知らせ・記事一覧",
    router: "/informations",
    path: "/informations",
    isPrivate: true,
    component: <InformationList />,
  },
  informationList: {
    title: "お知らせ・記事一覧",
    router: "/informations?list=true",
    path: "/informations?list=true",
    isPrivate: true,
    component: <InformationList />,
  },
  mypage: {
    title: "マイページ",
    router: "/mypage",
    path: "/mypage",
    isPrivate: true,
    component: <MyPage />,
  },
  files: {
    title: "ファイル",
    router: "/files",
    path: "/files",
    isPrivate: true,
    component: <FilesPage />,
  },
  forder: {
    title: "フォルダ",
    router: "/files/:folderId",
    path: "/files/:folderId",
    isPrivate: true,
    component: <FolderPage />,
  },
  pointRequest: {
    title: "ポイント申請",
    router: "/point_request",
    path: "/point_request",
    isPrivate: true,
    component: <PointRequest />,
  },
  pointHistory: {
    title: "ポイント履歴",
    router: "/point_history",
    path: "/point_history",
    isPrivate: true,
    component: <PointHistory />,
  },
  memberEdit: {
    title: "会員情報変更",
    router: "/members/edit",
    path: "/members/edit",
    isPrivate: true,
    component: <MemberEdit />,
  },
  login: {
    title: "ログイン",
    router: "/login",
    path: "/login",
    isPrivate: false,
    component: <Login />,
  },
  contact: {
    title: "お問合せ",
    router: "/contact",
    path: "/contact",
    isPrivate: false,
    component: <Contact />,
  },
  chat: {
    title: "チャット",
    router: "/chat",
    path: "/chat",
    isPrivate: true,
    component: <Chat />,
  },
  memberEntrySkip: {
    title: "本登録入力フォーム",
    router: "/members/entry/:id/:datetimeHash",
    path: (id: string, datetimeHash: string) =>
      `/members/entry/${id}/${datetimeHash}`,
    isPrivate: false,
    component: <MemberEntry />,
  },
  memberEntry: {
    title: "本登録入力フォーム",
    router: "/members/entry",
    path: "/members/entry",
    isPrivate: false,
    component: <MemberEntryNomal />,
  },
  memberIdentification: {
    title: "メール認証完了",
    router: "/members/identification/:id/:datetimeHash",
    path: (id: string, datetimeHash: string) =>
      `/members/identification/${id}/${datetimeHash}`,
    isPrivate: false,
    component: <MemberIdentification />,
  },
  mailAddressConfirm: {
    title: "ご本人様確認",
    router: "/members/mailAddressConfirm",
    path: "/members/mailAddressConfirm",
    isPrivate: false,
    component: <MailAddressConfirm />,
  },
  resetPassword: {
    title: "パスワードリセット",
    router: "/reset_password/entry/:id/:datetimeHash",
    path: (id: string, datetimeHash: string) =>
      `/reset_password/entry/${id}/${datetimeHash}`,
    isPrivate: false,
    component: <ResetPassword />,
  },
  resetPasswordMailAddressConfirm: {
    title: "パスワード変更",
    router: "/reset_password/mailAddressConfirm",
    path: "/reset_password/mailAddressConfirm",
    isPrivate: false,
    component: <ResetPasswordMailAddressConfirm />,
  },
  eventInvitation: {
    title: "イベント申し込みフォーム",
    router: "/event_invitation/:eventId",
    path: (id: string) => `/event_invitation/${id}`,
    isPrivate: false,
    component: <EventInvitation />,
  },
  staticDetail: {
    router: "/static/:slug",
    path: (slug: string) => `/static/${slug}`,
    isPrivate: false,
    component: <StaticDetail />,
  },
  staticError: {
    title: "エラー",
    router: "/staticError",
    path: "/staticError",
    isPrivate: false,
    component: <StaticError />,
  },
  faq: {
    title: "よくあるご質問",
    router: "/faq",
    path: "/faq",
    isPrivate: false,
    component: <Faq />,
  },
  beginner: {
    title: "初めての方へ",
    router: "/beginners",
    path: "/beginners",
    isPrivate: false,
    component: <Beginner />,
  },
  term: {
    title: "利用規約",
    router: "/kiyaku",
    path: "/kiyaku",
    isPrivate: false,
    component: <Term />,
  },
  privacy: {
    title: "プライバシーポリシー",
    router: "/privacy",
    path: "/privacy",
    isPrivate: false,
    component: <Privacy />,
  },
  memberPremium: {
    title: "会員優待特典について",
    router: "/member_premium",
    path: "/member_premium",
    isPrivate: false,
    component: <MemberPremium />,
  },
  referral: {
    title: "紹介",
    router: "/referral",
    path: "/referral",
    isPrivate: false,
    component: <Referral />,
  },
  survey_kodate: {
    title: "アンケート/戸建て",
    router: "/survey_kodate",
    path: "/survey_kodate",
    isPrivate: false,
    component: <SurveyKodate />,
  },
  survey_mansion: {
    title: "アンケート/マンション",
    router: "/survey_mansion",
    path: "/survey_mansion",
    isPrivate: false,
    component: <SurveyMansion />,
  },
  survey: {
    title: "アンケート",
    router: "/survey",
    path: "/survey",
    isPrivate: false,
    component: <Survey />,
  },
  contentFixedPreview: {
    title: "固定ページプレビュー",
    router: "/contents/fixed/preview/:key",
    path: (key: string) => `/contents/fixed/preview/${key}`,
    isPrivate: false,
    component: <ContentFixedPreview />,
  },
  SitePreview: {
    title: "TOPページプレビュー",
    router: "/site/preview",
    path: (key: string) => `/site/preview?key=${key}`,
    isPrivate: false,
    component: <TopPreview />,
  },
  contentPreview: {
    title: "記事プレビュー",
    router: "/contents/preview/:key",
    path: (key: string) => `/contents/preview/${key}`,
    isPrivate: false,
    component: <ContentPreview />,
  },
  chatOff: {
    title: "エラー",
    router: "/chat_off",
    path: "/chat_off",
    isPrivate: false,
    component: <ChatOff />,
  },
  error: {
    title: "エラー",
    router: "/error",
    path: "/error",
    isPrivate: false,
    component: <Error />,
  },
};

export const Router = () => {
  usePageTracking();
  const { loginUser } = useContext(LoginUserContext);
  const site = useContext(SiteContext);

  const location = useLocation();

  useUserSession(location);

  if (!site.isPublic) return <Error />;

  return (
    <Switch>
      {Object.entries(routes)
        .map(([_, v]) => v)
        .filter((v) => v.isPrivate)
        .map((route) => {
          if (loginUser) {
            return (
              <Route exact path={route.router}>
                {route.component}
              </Route>
            );
          } else {
            return (
              <Route
                exact
                path={route.router}
                render={({ location }) => (
                  <Redirect
                    to={{
                      pathname: routes.login.path,
                      state: { from: location },
                    }}
                  />
                )}
              />
            );
          }
        })}
      {Object.entries(routes)
        .map(([_, v]) => v)
        .filter((v) => !v.isPrivate)
        .map((route) => {
          return (
            <Route exact path={route.router}>
              {route.component}
            </Route>
          );
        })}
      <Route component={Error} />
    </Switch>
  );
};
