import STErrorCard from "../../assets/styles/organisms/errorCard.module.scss";
import { Link } from "react-router-dom";
import { routes } from "../../router/Router";

interface ErrorCardProps {
  isChatOff?: boolean
}

const ErrorCard:React.FC<ErrorCardProps>  = ({isChatOff}) => {
  return (
    <div className={STErrorCard.errorCard}>
      <h1 className={STErrorCard.title}>{isChatOff ? 'チャット機能がOFFになっています。' : 'エラーです。'}</h1>
      <div className={STErrorCard.body}>
        <p className={STErrorCard.text}>
          {isChatOff ? '弊社へのご連絡は、「お問い合わせ」よりお願いいたします。' : '指定されたページもしくはデータが存在しません。再度お試しください。'}
        </p>
        <p className={STErrorCard.text}>
          {isChatOff ? '' : '解決しない場合は、担当者までご連絡ください'}
        </p>
      </div>
      {!isChatOff && 
        <p>
          <Link to={routes.top.path} className={STErrorCard.link}>
            トップページへ
          </Link>
        </p>
      }
    </div>
  );
};

export default ErrorCard;
