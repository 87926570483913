import { Box, makeStyles, Button, Link } from "@material-ui/core";
import Image from "../atoms/Image";
import { TPopupSettingResponse } from "../../api/popup_setting";
const useStyles = makeStyles(() => ({
  detailButton: {
    fontWeight: "bold",
    borderRadius: "100px",
    margin: "32px auto auto auto",
    width: "200px",
    fontSize: "16px",
    padding: "12px 50px",
    background: "#f50057",
    " &:hover": {
      opacity: "0.6",
    },
  },
  text: {
    width: "223px",
    fontSize: "14px",
    margin: "auto",
    padding: "16px 0 0 0",
    textAlign: "center",
    whiteSpace: "pre-wrap",
  },

  closeIcon: {
    width: "20px",
    height: "20px",
    position: "absolute",
    top: "4px",
    right: "4px",
  },
  closeButton: {
    color: "#2D3748",
    fontSize: "14px",
    fontWeight: 500,
    margin: "32px auto auto auto",
    textDecoration: "underline",
    "&:hover": {
      background: "none",
    },
  },
  popupImg: {
    maxWidth: "300px",
    width: "100%",
    height: "auto",
    objectFit: "cover",
    cursor: "pointer",
  },
}));
type Props = {
  popupSetting: TPopupSettingResponse;
  handleClosePopup: () => void;
  saveInvisibleDate: () => void;
  updateInvisibleUpdatedAt: () => void;
};
const PopupCustom = ({
  popupSetting,
  handleClosePopup,
  saveInvisibleDate,
  updateInvisibleUpdatedAt,
}: Props) => {
  const classes = useStyles();

  const handlePopupClose = () => {
    //設定情報の変更がない限り永久非表示
    updateInvisibleUpdatedAt();
    handleClosePopup();
  };
  const openDetailPage = () => {
    //24時間非表示
    saveInvisibleDate();
    handleClosePopup();
  };

  //画像がなければ非表示
  if (!popupSetting.img_url) {
    updateInvisibleUpdatedAt();
    handleClosePopup();
  }

  /**
   * ボタンの色の濃さから文字の色を黒か白に変更する処理
   *
   * @param hex
   * @returns string
   */
  const getContrastTextColor = (hex: string | undefined): string => {
    if (!hex) return "";
    const r = parseInt(hex!.slice(1, 3), 16);
    const g = parseInt(hex!.slice(3, 5), 16);
    const b = parseInt(hex!.slice(5, 7), 16);

    // 光度を計算
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    // 光度が0.5より大きい場合、文字色を黒にする
    // それ以外の場合、文字色を白にする
    return luminance > 0.5 ? "#000000" : "#FFFFFF";
  };

  return (
    <>
      <Image
        imageUrl={popupSetting.img_url}
        alt={"ポップアップ画像"}
        className={classes.popupImg}
        shownDefault={true}
      />
      <Box style={{ paddingBottom: "32px" }}>
        {popupSetting.description && (
          <Box className={classes.text}>{popupSetting.description}</Box>
        )}
        <Box textAlign={"center"} display={"flex"} flexDirection={"column"}>
          {popupSetting.link_url && (
            <Link
              className={classes.detailButton}
              color="secondary"
              href={popupSetting.link_url}
              style={{
                color: `${getContrastTextColor(popupSetting.primary_color)}`,
                textDecoration: "none",
                fontWeight: "700",
                fontSize: "14px",
                background: `${popupSetting.primary_color}`,
              }}
              target="_blank"
              onClick={openDetailPage}
            >
              詳細を見る
            </Link>
          )}
          <Button className={classes.closeButton} onClick={handlePopupClose}>
            閉じる
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default PopupCustom;
