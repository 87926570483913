import { getAuth } from "firebase/auth";
import { TChatConfigResponse } from "../../api/chat_config";
import TApiResult from "../../types/api/TApiResult";
import tokenNotFound from "./config/tokenNotFound";
import client from "../../api/client";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";


export type TApiChatConfigResult = {
    result?: TChatConfigResponse;
};

export const fetchChatConfig = async () : Promise<
    TApiResult & TApiChatConfigResult
> => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    return client()
        .chat_config.get({
            headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
            return {
                isSuccess: true,
                status: 200,
                message: "",
                result: res.body,
            };
            })
            .catch((e: AxiosError) => {
            const { status, message } = getErrorProperty(e, "fetchChatConfig");
            return {
                isSuccess: false,
                status: status,
                message: message,
            };
        });
}
