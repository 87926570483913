import { Box, makeStyles } from "@material-ui/core"
import PopupScratch from "../../components/organisms/PopupScratch"
import PopupCustom from "../../components/organisms/PopupCustom";
import {
  ScratchSetting, PlayedScratch
} from "../../types/popup";
import { TPopupSettingResponse } from "../../api/popup_setting";
import { TBannerSettingResponse } from "../../api/banner_setting";
import { useCallback } from 'react'
type props = {
  popupSetting: TPopupSettingResponse;
  scratchSetting?: ScratchSetting;
  bannerSetting?: TBannerSettingResponse;
  playedScratch?: PlayedScratch;
  isOpenClickScratchBanner: boolean;
  handleClosePopup: () => void;
  saveInvisibleDate: (popup?: TPopupSettingResponse) => void;
  saveInvisibleUpdatedAt: (popupUpdatedAt?: string) => void;
  handleCloseBanner: () => void;
};
const useStyles = makeStyles(() => ({
  popupScratch: {
    overflowY: "initial",
  },
  popupCustom: {
    overflowY: "scroll",
  },
}));
const Popup = ({
  popupSetting,
  scratchSetting,
  playedScratch,
  isOpenClickScratchBanner,
  handleClosePopup,
  saveInvisibleDate,
  saveInvisibleUpdatedAt,
  handleCloseBanner,
}: props) => {
  const classes = useStyles();
  /**
   * ローカルストレージに更新日付を保存する
   *
   */
  const updateInvisibleUpdatedAt = useCallback(() => {
    saveInvisibleUpdatedAt(popupSetting.updated_at);
  }, [popupSetting.updated_at, saveInvisibleUpdatedAt]);

  const updateSaveInvisibleDate = useCallback(() => {
    saveInvisibleDate(popupSetting);
  }, [popupSetting]);

  const handleClosePopupOrBanner = useCallback(() => {
    handleClosePopup();
    handleCloseBanner();
  }, [handleClosePopup, handleCloseBanner]);

  const Content = useCallback(() => {
    // 2:スクラッチ
    if (popupSetting.display_type === 2 || isOpenClickScratchBanner) {
      return (
        <PopupScratch
          popupSetting={popupSetting}
          scratchRes={scratchSetting! as ScratchSetting}
          playedScratch={playedScratch}
          handleClosePopupOrBanner={handleClosePopupOrBanner}
          saveInvisibleDate={updateSaveInvisibleDate}
          updateInvisibleUpdatedAt={updateInvisibleUpdatedAt}
        />
      );
      //1:カスタマイズ
    } else {
      return (
        <PopupCustom
          popupSetting={popupSetting}
          handleClosePopup={handleClosePopup}
          saveInvisibleDate={updateSaveInvisibleDate}
          updateInvisibleUpdatedAt={updateInvisibleUpdatedAt}
        />
      );
    }
  }, [
    isOpenClickScratchBanner,
    playedScratch,
    scratchSetting,
    popupSetting,
    handleClosePopup,
    updateSaveInvisibleDate,
    updateInvisibleUpdatedAt,
    handleClosePopupOrBanner,
  ]);
  return (
    <Box
      bgcolor={"#fff"}
      position={"relative"}
      p={0}
      style={{
        boxShadow:
          "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
        borderRadius: "6px",
      }}
      className={
        popupSetting.display_type === 2 || isOpenClickScratchBanner
          ? classes.popupScratch
          : classes.popupCustom
      }
    >
      <Content />
    </Box>
  );
};
export default Popup
