import { getAuth } from "firebase/auth";
import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import tokenNotFound from "./config/tokenNotFound";

export type TApiMailMessageResult = {
    result?: {
        res: {};
    };
};

export const postMailMessage = async (params: {
    company_id: number,
    user_ids: number[],
    member_ids: number[],
    site_emails: string[],
    site_allow_chat_mail_sending: boolean
}): Promise<TApiResult & TApiMailMessageResult> => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    if (!token) return tokenNotFound;
    return client().mail_message.post({
        headers: { Authorization: `Bearer ${token}` },
        body: params
    }).then(res => {
        return {
            isSuccess: true,
            status: 200,
            message: "",
            result: {
              res: res.body,
            },
        };
    })
    
}