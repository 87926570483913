// sendbirdのメッセージ入力欄に日本語をペーストするとASCIIコードになってしまうのでpasteイベントを上書きする
export const customPasteFunction = (ref: React.RefObject<HTMLInputElement>) => {
  if (ref.current) {
    // MessageInputWrapperがrefを受け付けないのでClassで無理やり走査
    const elm = ref.current.getElementsByClassName('sendbird-message-input-text-field')[0];
    elm.addEventListener('paste', (event: any) => {
      // TODO 送信アイコンに変わらない（preventDefaultの影響）
      event.stopPropagation();
      event.preventDefault();
      const paste = (event.clipboardData || (window as any)).getData('text');
      const selection = window.getSelection();
      if (selection && selection.rangeCount) {
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();
      }
    });
  }
};
