import React, { useEffect } from "react";
import InfoArticleCardList from "../organisms/InfoArticleCardList";
import MemberInfoCard from "../organisms/MemberInfoCard";
import News from "../organisms/News";
import BaseHead from "../templates/BaseHead";
import { getSubdomain } from "../../utils/getSubdomain";
import STUtils from "../../assets/styles/utility.module.scss";
import CategoryCardList from "../organisms/CategoryCardList";
import RecommendContentsList from "../organisms/RecommendContentsList";
import { routes } from "../../router/Router";
import TopBackground from "../organisms/TopBackground";
import RecommendVideosList from "../organisms/RecommendVideosList";
import Popup from "../organisms/Popup";
import useModal from "../../hooks/useModal";
import useAutomaticPopup from "../../hooks/useAutomaticPopup";
import { useContext, useState } from "react";
import useSaveHistoryToLocalStorage from "../../hooks/useSaveHistoryToLocalStorage";
import { TPopupSettingResponse } from "../../api/popup_setting";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { SiteContext } from "../../providers/SiteProvider";
import MemberInfoCardNew from "../organisms/MemberInforCardNew";
import AddToHomeScreen from "../molecules/AddToHomeScreen";
import MenuList from "../organisms/MenuList";
import { useIsMobile } from "../../hooks/useIsMobile";

const Top: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const { enablesHomePopup } = useContext(SiteContext);
  const { isMobile } = useIsMobile();
  const {
    modalComponent: Modal,
    isOpenClickScratchBanner,
    handleOpenPopup,
    handleClosePopup,
    handleOpenBanner,
    handleCloseBanner,
  } = useModal(false);

  const { saveInvisibleDate, saveInvisibleUpdatedAt } =
    useSaveHistoryToLocalStorage();

  const {
    shouldDisplayWhenLoaded,
    scratchSetting,
    setScratchSetting,
    popupSetting,
    playedScratch,
    isLoading,
    error,
  } = useAutomaticPopup();

  const site = useContext(SiteContext);
  const newUiVersion = site.newUIVersion;

  useEffect(() => {
    postMemberAccessLog(ACCESS_LOG_ACTION.topPage, document.referrer);
  }, []);

  const [isViewedHomePopup, setIsViewedHomePopup] = useState(() =>
    localStorage.getItem("isViewedHomePopup")
  );

  useEffect(() => {
    if (!enablesHomePopup || isViewedHomePopup) return;
    // ローカルストレージの変更は再レンダリングされないと検知できないため、1秒ごとに確認する
    const intervalId = setInterval(() => {
      const storage = localStorage.getItem("isViewedHomePopup");
      setIsViewedHomePopup(storage);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [enablesHomePopup, isViewedHomePopup]);

  useEffect(() => {
    // ホームポップアップ表示中はポップアップは表示しない
    const isPopupNowVisible =
      isMobile && enablesHomePopup && !isViewedHomePopup;
    if (isLoading) return;
    if (isPopupNowVisible) return;

    if (shouldDisplayWhenLoaded || isOpenClickScratchBanner) {
      handleOpenPopup();
    } else {
      handleClosePopup();
    }
  }, [
    isLoading,
    shouldDisplayWhenLoaded,
    isOpenClickScratchBanner,
    handleOpenPopup,
    handleClosePopup,
    enablesHomePopup,
    isViewedHomePopup,
    isMobile,
  ]);

  const onClickScratchBanner = () => {
    handleOpenPopup();
    handleOpenBanner();
  };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div style={{pointerEvents: 'none'}}>
       <BaseHead
        title={routes.top.title}
        login={true}
        subdomain={subdomain}
        hasBackground={true}
        hasNavigationBar={true}
      >
        {!newUiVersion && <TopBackground />}
        <div className={STUtils.container_large}>
          {!isLoading && !error && (
            <Modal>
              <Popup
                popupSetting={popupSetting! as TPopupSettingResponse}
                scratchSetting={scratchSetting}
                playedScratch={playedScratch}
                isOpenClickScratchBanner={isOpenClickScratchBanner}
                handleClosePopup={handleClosePopup}
                handleCloseBanner={handleCloseBanner}
                saveInvisibleDate={saveInvisibleDate}
                saveInvisibleUpdatedAt={saveInvisibleUpdatedAt}
              />
            </Modal>
          )}
          <div onClick={handleOverlayClick}>
            {!newUiVersion ? (
              <MemberInfoCard
                scratchSetting={scratchSetting}
                onClickScratchBanner={onClickScratchBanner}
                setScratchSetting={setScratchSetting}
              />
            ) : (
              <>
                <RecommendContentsList />
                <div
                  style={{
                    marginTop: window.innerWidth < 800 ? "-70px" : "-85px",
                  }}
                >
                  <MemberInfoCardNew inHeader={false} />
                </div>
                <div className={STUtils.dn_pc}>
                  <AddToHomeScreen />
                </div>
                <MenuList
                  scratchSetting={scratchSetting}
                  onClickScratchBanner={onClickScratchBanner}
                  setScratchSetting={setScratchSetting}
                />
              </>
            )}
            <News />
            {!newUiVersion && <RecommendContentsList />}
            <RecommendVideosList />
            <CategoryCardList />
            {!newUiVersion && <InfoArticleCardList />}
          </div>
        </div>
      </BaseHead>
    </div>
  );
};

export default Top;
