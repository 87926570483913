/**
 * NOTE:
 * Do not forget to update the string set table on Docs
 * When you update this string set
 *
 * `%d` will be replaced by a proper number
 */

// TODO: Make StringSet as a interface
export type StringSet = Record<keyof typeof stringSet['ja'], string>;
const stringSet = {
ja: {
    // Group Channel - Conversation
    MESSAGE_STATUS__YESTERDAY: '昨日',
    CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE: '以降の新しいメッセージ',
    /** @deprecated Please use `DATE_FORMAT__MESSAGE_LIST__NOTIFICATION__UNREAD_SINCE` instead * */
    CHANNEL__MESSAGE_LIST__NOTIFICATION__ON: 'に',
    // Channel List
    CHANNEL_PREVIEW_MOBILE_LEAVE: 'チャンネルを退出',
    // Group Channel - Settings
    CHANNEL_SETTING__HEADER__TITLE: 'チャンネル情報',
    CHANNEL_SETTING__PROFILE__EDIT: '編集',
    CHANNEL_SETTING__MEMBERS__TITLE: 'メンバー',
    CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS: 'すべてのメンバー',
    CHANNEL_SETTING__MEMBERS__INVITE_MEMBER: 'ユーザーを招待',
    CHANNEL_SETTING__MEMBERS__YOU: ' (あなた)',
    CHANNEL_SETTING__MEMBERS__SELECT_TITLE: 'メンバーを選択',
    CHANNEL_SETTING__MEMBERS__OPERATOR: 'オペレーター',
    CHANNEL_SETTING__LEAVE_CHANNEL__TITLE: 'チャンネルを退出',
    CHANNEL_SETTING__OPERATORS__TITLE: 'オペレーター',
    CHANNEL_SETTING__OPERATORS__TITLE_ALL: 'すべてのオペレーター',
    CHANNEL_SETTING__OPERATORS__TITLE_ADD: 'オペレーターを追加',
    CHANNEL_SETTING__OPERATORS__ADD_BUTTON: '追加',
    CHANNEL_SETTING__MUTED_MEMBERS__TITLE: 'ミュートされたメンバー',
    CHANNEL_SETTING__MUTED_MEMBERS__TITLE_ALL: 'すべてのミュートされたメンバー',
    CHANNEL_SETTING__NO_UNMUTED: 'まだミュートされたメンバーはいません',
    CHANNEL_SETTING__BANNED_MEMBERS__TITLE: 'BANされたユーザー',
    CHANNEL_SETTING__FREEZE_CHANNEL: 'チャンネルを凍結',
    CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR: 'オペレーターとして登録',
    CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR: 'オペレーターの登録解除',
    CHANNEL_SETTING__MODERATION__MUTE: 'ミュート',
    CHANNEL_SETTING__MODERATION__UNMUTE: 'ミュート解除',
    CHANNEL_SETTING__MODERATION__BAN: 'BAN',
    CHANNEL_SETTING__MODERATION__UNBAN: 'BAN解除',
    CHANNEL_SETTING__MODERATION__EMPTY_BAN: 'まだBANされたメンバーはいません',
    CHANNEL_SETTING__MODERATION__ALL_BAN: 'すべてのBANされたメンバー',
    // OpenChannel - Conversation
    OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS: '参加者',
    OPEN_CHANNEL_CONVERSATION__SELECT_PARTICIPANTS: '参加者を選択',
    // OpenChannelList
    OPEN_CHANNEL_LIST__TITLE: 'チャンネル',
    CREATE_OPEN_CHANNEL_LIST__TITLE: '新しいチャンネルプロフィール',
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_SECTION: 'チャンネル画像',
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_UPLOAD: 'アップロード',
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_SECTION: 'チャンネル名',
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_PLACE_HOLDER: 'チャンネル名を入力してください',
    CREATE_OPEN_CHANNEL_LIST__SUBMIT: '作成',
    // OpenChannel - Settings
    OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE: 'チャンネル情報',
    OPEN_CHANNEL_SETTINGS__OPERATOR_URL: 'URL',
    OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE: '参加者',
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL: 'チャンネルを削除',
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE: 'チャンネルを削除しますか？',
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_CONTEXT: '一度削除すると、このチャンネルは復元できません。',
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT: '削除',
    OPEN_CHANNEL_SETTINGS__OPERATORS_TITLE: 'オペレーター',
    OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ADD: 'オペレーターを追加',
    OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ALL: 'すべてのオペレーター',
    OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE: 'ミュートされた参加者',
    OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE_ALL: 'すべてのミュートされた参加者',
    OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__NO_ONE: 'まだミュートされた参加者はいません',
    OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE: 'BANされたユーザー',
    OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE_ALL: 'すべてのBANされたユーザー',
    OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__NO_ONE: 'まだBANされたユーザーはいません',
    OPEN_CHANNEL_SETTINGS__MEMBERS__YOU: '(あなた)',
    OPEN_CHANNEL_SETTINGS__MEMBERS__OPERATOR: 'オペレーター',
    OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE: '参加者',
    OPEN_CHANNEL_SETTINGS__EMPTY_LIST: 'まだ参加者はいません',
    OPEN_CHANNEL_SETTINGS__SEE_ALL: 'すべての参加者を見る',
    OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE: 'すべての参加者',
    OPEN_CHANNEL_SETTINGS__NO_TITLE: '(タイトルなし)',
    OPEN_CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR: 'オペレーターとして登録',
    OPEN_CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR: 'オペレーターの登録解除',
    OPEN_CHANNEL_SETTING__MODERATION__MUTE: 'ミュート',
    OPEN_CHANNEL_SETTING__MODERATION__UNMUTE: 'ミュート解除',
    OPEN_CHANNEL_SETTING__MODERATION__BAN: 'BAN',
    OPEN_CHANNEL_SETTING__MODERATION__UNBAN: 'BAN解除',
    // Channel - Common
    TRYING_TO_CONNECT: '接続中…',
    TYPING_INDICATOR__IS_TYPING: 'が入力中...',
    TYPING_INDICATOR__AND: 'と',
    TYPING_INDICATOR__ARE_TYPING: 'が入力中...',
    TYPING_INDICATOR__MULTIPLE_TYPING: '複数の人が入力中...',
    CHANNEL_FROZEN: 'チャンネルが凍結されています',
    PLACE_HOLDER__NO_CHANNEL: 'チャンネルがありません',
    PLACE_HOLDER__WRONG: '問題が発生しました',
    PLACE_HOLDER__RETRY_TO_CONNECT: '再試行',
    PLACE_HOLDER__NO_MESSAGES: 'メッセージがありません',
    TOOLTIP__AND_YOU: '、あなた',
    TOOLTIP__YOU: 'あなた',
    TOOLTIP__UNKNOWN_USER: '(名前なし)',
    UNKNOWN__UNKNOWN_MESSAGE_TYPE: '(不明なメッセージタイプ)',
    UNKNOWN__CANNOT_READ_MESSAGE: 'このメッセージを読み取れません。',
    UNKNOWN__TEMPLATE_ERROR: '(テンプレートエラー)',
    FORM_VERSION_ERROR: 'このバージョンではフォームタイプのメッセージは利用できません。',
    UNKNOWN__CANNOT_READ_TEMPLATE: 'このテンプレートを読み取れません。',
    MESSAGE_EDITED: '(編集済み)',
    // Channel - Modal
    MODAL__DELETE_MESSAGE__TITLE: 'このメッセージを削除しますか？',
    MODAL__CHANNEL_INFORMATION__TITLE: 'チャンネル情報を編集',
    MODAL__CHANNEL_INFORMATION__CHANNEL_IMAGE: 'チャンネル画像',
    MODAL__CHANNEL_INFORMATION__UPLOAD: 'アップロード',
    MODAL__CHANNEL_INFORMATION__CHANNEL_NAME: 'チャンネル名',
    MODAL__CHANNEL_INFORMATION__INPUT__PLACE_HOLDER: '名前を入力してください',
    MODAL__INVITE_MEMBER__TITLE: 'メンバーを招待',
    MODAL__INVITE_MEMBER__SELECTED: '選択済み',
    MODAL__CHOOSE_CHANNEL_TYPE__TITLE: '新しいチャンネル',
    MODAL__CHOOSE_CHANNEL_TYPE__GROUP: 'グループ',
    MODAL__CHOOSE_CHANNEL_TYPE__SUPER_GROUP: 'スーパーグループ',
    MODAL__CHOOSE_CHANNEL_TYPE__BROADCAST: 'ブロードキャスト',
    MODAL__CREATE_CHANNEL__TITLE: '新しいチャンネル',
    MODAL__CREATE_CHANNEL__GROUP: 'グループ',
    MODAL__CREATE_CHANNEL__SUPER: 'スーパーグループ',
    MODAL__CREATE_CHANNEL__BROADCAST: 'ブロードキャスト',
    MODAL__CREATE_CHANNEL__SELECTED: '選択済み',
    MODAL__LEAVE_CHANNEL__TITLE: 'このチャンネルを退出しますか？',
    MODAL__LEAVE_CHANNEL__FOOTER: '退出',
    MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_MUTED: 'あなたはオペレーターによってミュートされています。',
    MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_FROZEN: 'チャンネルが凍結されています。',
    // User Profile
    USER_PROFILE__MESSAGE: 'メッセージ',
    USER_PROFILE__USER_ID: 'ユーザーID',
    EDIT_PROFILE__TITLE: 'マイプロフィール',
    EDIT_PROFILE__IMAGE_LABEL: 'プロフィール画像',
    EDIT_PROFILE__IMAGE_UPLOAD: 'アップロード',
    EDIT_PROFILE__NICKNAME_LABEL: 'ニックネーム',
    EDIT_PROFILE__NICKNAME_PLACEHOLDER: 'ニックネームを入力してください',
    EDIT_PROFILE__USERID_LABEL: 'ユーザーID',
    EDIT_PROFILE__THEME_LABEL: 'ダークテーマ',
    // Message Input
    MESSAGE_INPUT__PLACE_HOLDER: 'メッセージを入力してください',
    MESSAGE_INPUT__PLACE_HOLDER__DISABLED: 'このチャンネルではチャットできません',
    MESSAGE_INPUT__PLACE_HOLDER__SUGGESTED_REPLIES: '上記から一つ選択してください',
    MESSAGE_INPUT__PLACE_HOLDER__MESSAGE_FORM: 'メッセージフォームに記入してください',
    MESSAGE_INPUT__PLACE_HOLDER__FROZEN: 'このチャンネルではチャットできません',
    MESSAGE_INPUT__PLACE_HOLDER__MUTED: 'ミュートされているため、チャットできません',
    MESSAGE_INPUT__PLACE_HOLDER__MUTED_SHORT: 'あなたはミュートされています',
    MESSAGE_INPUT__QUOTE_REPLY__PLACE_HOLDER: 'メッセージに返信',
    // Common UI
    BUTTON__SUBMIT: '送信',
    BUTTON__CANCEL: '取消',
    BUTTON__DELETE: '削除',
    BUTTON__SAVE: '保存',
    BUTTON__CREATE: '作成',
    BUTTON__INVITE: '招待',
    BUTTON__OK: 'OK',
    BADGE__OVER: '+',
    NO_TITLE: 'タイトルなし',
    NO_NAME: '(名前なし)',
    NO_MEMBERS: '(メンバーなし)',
    LABEL__OPERATOR: 'オペレーター',
    // Context Menu
    MESSAGE_MENU__COPY: 'コピー',
    MESSAGE_MENU__REPLY: '返信',
    MESSAGE_MENU__THREAD: 'スレッドで返信',
    MESSAGE_MENU__OPEN_IN_CHANNEL: 'チャンネルで開く',
    MESSAGE_MENU__EDIT: '編集',
    MESSAGE_MENU__RESEND: '再送信',
    MESSAGE_MENU__DELETE: '削除',
    MESSAGE_MENU__SAVE: '保存',
    //  * FIXME: get back legacy, remove after refactoring open channel messages *
    CONTEXT_MENU_DROPDOWN__COPY: 'コピー',
    CONTEXT_MENU_DROPDOWN__EDIT: '編集',
    CONTEXT_MENU_DROPDOWN__RESEND: '再送信',
    CONTEXT_MENU_DROPDOWN__DELETE: '削除',
    CONTEXT_MENU_DROPDOWN__SAVE: '保存',
    // Feature - Message Search
    SEARCH: '検索',
    SEARCH_IN_CHANNEL: 'チャンネル内を検索',
    SEARCH_IN: 'グループの参加者',
    SEARCHING: 'メッセージを検索中...',
    NO_SEARCHED_MESSAGE: '結果が見つかりません',
    // Feature - Message Reply
    QUOTE_MESSAGE_INPUT__REPLY_TO: '返信',
    QUOTE_MESSAGE_INPUT__FILE_TYPE_IMAGE: '写真',
    QUOTE_MESSAGE_INPUT__FILE_TYPE_GIF: 'GIF',
    QUOTE_MESSAGE_INPUT__FILE_TYPE__VIDEO: 'ビデオ',
    QUOTED_MESSAGE__REPLIED_TO: '返信',
    QUOTED_MESSAGE__CURRENT_USER: 'あなた',
    QUOTED_MESSAGE__UNAVAILABLE: 'メッセージが利用できません',
    // Feature - Thread
    THREAD__HEADER_TITLE: 'スレッド',
    CHANNEL__THREAD_REPLY: '返信',
    CHANNEL__THREAD_REPLIES: '返信',
    CHANNEL__THREAD_OVER_MAX: '99+',
    THREAD__THREAD_REPLY: '返信',
    THREAD__THREAD_REPLIES: 'replies',
    THREAD__INPUT__REPLY_TO_THREAD: 'スレッドに返信',
    THREAD__INPUT__REPLY_IN_THREAD: 'スレッドで返信',
    // Feature - Mention
    MENTION_NAME__NO_NAME: '(名前なし)',
    MENTION_COUNT__OVER_LIMIT: '一度に最大%d回メンションできます。',
    UI__FILE_VIEWER__UNSUPPORT: 'サポートされていないメッセージ',
    // Feature - Voice Message
    VOICE_RECORDING_PERMISSION_DENIED: 'デバイスのシステム設定で音声録音が許可されていないため、音声を録音できません',
    VOICE_MESSAGE: 'ボイスメッセージ',
    // Channel preview last message file type display strings
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GIF: 'GIF',
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_PHOTO: '写真',
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VIDEO: 'ビデオ',
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_AUDIO: '音声',
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VOICE_MESSAGE: 'ボイスメッセージ',
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GENERAL: 'ファイル',
    CHANNEL_PREVIEW_LAST_MESSAGE_TEMPLATE_MESSAGE: 'メッセージ',
    // Date format
    DATE_FORMAT__MESSAGE_LIST__NOTIFICATION__UNREAD_SINCE: 'p \'に\' MMM dd',
    DATE_FORMAT__MESSAGE_LIST__DATE_SEPARATOR: 'MMMM dd, yyyy',
    DATE_FORMAT__THREAD_LIST__DATE_SEPARATOR: 'MMM dd, yyyy',
    // File upload
    FILE_UPLOAD_NOTIFICATION__COUNT_LIMIT: '最大%dファイルを添付できます。',
    FILE_UPLOAD_NOTIFICATION__SIZE_LIMIT: 'ファイルごとの最大サイズは%d MBです。',
    // Feedback button text
    FEEDBACK_LIKE: 'いいね',
    FEEDBACK_DISLIKE: 'not いいね',
    // Mobile feedback options menu items
    EDIT_COMMENT: 'コメントを編集',
    REMOVE_FEEDBACK: 'フィードバックを削除',
    // Feedback modal title
    FEEDBACK_MODAL_TITLE: '追加のフィードバックを提供 (任意)',
    FEEDBACK_CONTENT_PLACEHOLDER: 'コメントを残してください',
    BUTTON__REMOVE_FEEDBACK: 'フィードバックを削除',
    // Feedback failed modal title
    FEEDBACK_FAILED_SUBMIT: '送信できませんでした。もう一度お試しください。',
    FEEDBACK_FAILED_SAVE: '保存できませんでした。もう一度お試しください。',
    FEEDBACK_FAILED_DELETE: '削除できませんでした。もう一度お試しください。',
    // Form submit failed error messages
    FORM_ITEM_REQUIRED: 'このフィールドは必須です',
    FORM_ITEM_INVALID: '値を確認してください',
    FORM_ITEM_OPTIONAL_EMPTY: '無回答',
  },
};

const getStringSet = (lang: keyof typeof stringSet = 'ja'): StringSet => {
  return stringSet[lang];
};

export default getStringSet;