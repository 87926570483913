import React, { useContext, useEffect, useRef, useState } from "react";
import GroupChannelList from "@sendbird/uikit-react/GroupChannelList";
import { GroupChannelListHeader } from "@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader";
import EditUserProfile from "@sendbird/uikit-react/EditUserProfile";
import "../../assets/styles/sendbird.css";
import { GroupChannelProvider } from "@sendbird/uikit-react/GroupChannel/context";
import GroupChannelHeader from "@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader";
import { GroupChannelUI } from "@sendbird/uikit-react/GroupChannel/components/GroupChannelUI";
import { MessageInputWrapper } from "@sendbird/uikit-react/GroupChannel/components/MessageInputWrapper";
import { GroupChannelListItem } from "@sendbird/uikit-react/GroupChannelList/components/GroupChannelListItem";
import { UnreadMessageContext } from "../../providers/ChatProvider";
import { SiteContext } from "../../providers/SiteProvider";
import MessageSearch from "@sendbird/uikit-react/MessageSearch";
import ComfirmFilesMessageModal from "./ComfirmFilesMessageModal";
import Icon from "@sendbird/uikit-react/ui/Icon";
import MessageContent from "@sendbird/uikit-react/ui/MessageContent";
import ChatCustomSeparator from "../molecules/ChatCustomSeparator";
import { RenderCustomSeparatorProps } from "@sendbird/uikit-react/types/types";
import { ChannelSettingsProvider } from "@sendbird/uikit-react/ChannelSettings/context";
import ChannelSettingsUI from "@sendbird/uikit-react/ChannelSettings/components/ChannelSettingsUI";
import { Box } from "@material-ui/core";
import { ChatCustomModerationPanel } from "./ChatCustomModerationPanel";
import { customPasteFunction } from "../../utils/pasteChatMessageInput";
import { LoginUserContext } from "../../providers/LoginUserProvider";
import { useLocation } from "react-router-dom";

type Props = {
  channel: any;
  setChannel: Function;
  sendMailMessage: Function;
};

const ChatPc = ({ channel, setChannel, sendMailMessage }: Props) => {
  const { updateUnreadCount } = useContext(UnreadMessageContext);
  const groupChatTextHeader = useContext(SiteContext).groupChatTextHeader;
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [showSetting, setShowSetting] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [fileMessageParams, setFileMessageParams] = useState<any>(null);
  const [isShowSearchMessage, setIsShowSearchMessage] =
    useState<boolean>(false);
  const {member} = useContext(LoginUserContext);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    customPasteFunction(inputRef);
  }, [inputRef.current]);

  const handleClose = () => {
    setOpen(false);
    setFileMessageParams(null);
  };

  const handleAgree = () => {
    setOpen(false);
    if (fileMessageParams) {
      if (fileMessageParams?.fileInfoList) {
        if (fileMessageParams.fileInfoList.length === 1) {
          channel.sendFileMessage(fileMessageParams?.fileInfoList[0]);
        } else {
          channel.sendMultipleFilesMessage(fileMessageParams);
        }
      } else {
        channel.sendFileMessage(fileMessageParams);
      }
      sendMailMessage();
      setFileMessageParams(null);
    }
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div
        style={{
          display: "flex",
          maxWidth: "1200px",
          margin: "13px auto 0 auto",
          height: "calc(100vh - 120px)",
        }}
      >
        <GroupChannelList
          key={new Date().toString()}
          allowProfileEdit={true}
          renderUserProfile={() => <EditUserProfile />}
          selectedChannelUrl={channel ? channel.url : ""}
          onChannelSelect={(channel: any) => {
            if (channel) {
              updateUnreadCount(channel.url, 0);
              setChannel(channel);
            }
          }}
          onChannelCreated={(channel: any) => {
            setChannel(channel);
          }}
          renderHeader={() => (
            <GroupChannelListHeader onEdit={() => setShowEditProfile(true)} />
          )}
          renderChannelPreview={(channel: any) => {
            const memberIdInChannel = channel?.channel?.members?.map(
              (member: any) => member.userId
            );
            return memberIdInChannel.includes(member?.sendbirdUserId) ? (
              <GroupChannelListItem
                tabIndex={channel?.tabIndex}
                channel={channel?.channel}
                onClick={channel?.onClick}
                renderChannelAction={channel?.renderChannelAction}
              />
            ) : (
              <></>
            );
          }}
        />
        {channel && (
          <GroupChannelProvider
            key={channel.url}
            onBeforeSendMultipleFilesMessage={(fileMessageCreateParams) => {
              setFileMessageParams(fileMessageCreateParams);
              setOpen(true);
              return fileMessageParams;
            }}
            onBeforeSendFileMessage={(fileMessageCreateParams) => {
              setFileMessageParams(fileMessageCreateParams);
              setOpen(true);
              return fileMessageParams;
            }}
            onBeforeSendUserMessage={(message) => {
              sendMailMessage();
              return message;
            }}
            channelUrl={channel.url}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
              }}
              ref={inputRef}
            >
              <GroupChannelUI
                renderChannelHeader={() => (
                  <>
                    <GroupChannelHeader
                      renderRight={() => (
                        <>
                          {/* 日本語での検索が可能になってからコメントアウト解除 */}
                          {/* <Icon type='SEARCH' fillColor='PRIMARY' onClick={() => {setIsShowSearchMessage(!isShowSearchMessage)}}/> */}
                          <Icon
                            type="INFO"
                            fillColor="PRIMARY"
                            onClick={() => {
                              setShowSetting(!showSetting);
                            }}
                          />
                        </>
                      )}
                    />
                    <div
                      style={{
                        fontSize: "14px",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {groupChatTextHeader}
                    </div>
                  </>
                )}
                renderMessageContent={(contentProps: any) => {
                  if (contentProps?.message?.message) {
                    if (contentProps?.message?.message.trim().length === 0) {
                      return <></>;
                    }
                  }
                  return <MessageContent {...contentProps} />;
                }}
                renderMessageInput={() => (
                  <div
                    style={{
                      maxWidth: "878px",
                      maxHeight: "300px",
                      overflow: "scroll",
                    }}
                  >
                    <MessageInputWrapper />
                  </div>
                )}
                renderCustomSeparator={(props: RenderCustomSeparatorProps) => (
                  <ChatCustomSeparator timestamp={props.message.createdAt} />
                )}
              />
            </div>
          </GroupChannelProvider>
        )}
        {showEditProfile && (
          <EditUserProfile
            onCancel={() => setShowEditProfile(false)}
            onEditProfile={() => setShowEditProfile(false)}
            onThemeChange={() => null}
          />
        )}
        {channel && showSetting && (
          <ChannelSettingsProvider
            channelUrl={channel.url}
            onCloseClick={() => setShowSetting(!showSetting)}
          >
            <ChannelSettingsUI
              renderChannelProfile={() => <Box />}
              renderLeaveChannel={() => <Box />}
              renderModerationPanel={() => (
                <ChatCustomModerationPanel
                  channelMembersList={channel.members}
                />
              )}
            />
          </ChannelSettingsProvider>
        )}
        {channel && isShowSearchMessage && (
          <MessageSearch
            onCloseClick={() => setIsShowSearchMessage(false)}
            channelUrl={channel.url}
          />
        )}
      </div>
      {fileMessageParams && (
        <ComfirmFilesMessageModal
          open={open}
          handleClose={handleClose}
          handleAgree={handleAgree}
          fileMessageParams={fileMessageParams}
          setFileMessageParams={setFileMessageParams}
        />
      )}
    </>
  );
};

export default ChatPc;
