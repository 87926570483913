import React, { useContext, useEffect, useState } from "react";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import BaseHead from "../templates/BaseHead";
import { getSubdomain } from "../../utils/getSubdomain";
import "../../assets/styles/sendbird.css";
import ChatPc from "../organisms/ChatPc";
import ChatMobile from "../organisms/ChatMobile";
import { useHistory } from "react-router-dom";
import { routes } from "../../router/Router";
import { SiteContext } from "../../providers/SiteProvider";
import { postMailMessage } from "../../utils/api/postMailMessage";
import { LoginUserContext } from "../../providers/LoginUserProvider";
import { TApiMemberDetailResult, fetchMemberDetail } from "../../utils/api/fetchMemberDetail";
import TApiResult from "../../types/api/TApiResult";
import { TAppMember } from "../../types/TAppMember";

const Chat = () => {
  const [channel, setChannel] = useState<any>(null);
  const subdomain = getSubdomain(window.location.hostname);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 799);
  const context = useContext(SiteContext);
  const client = useContext(LoginUserContext);
  const history = useHistory();
  const updateMedia = () => {
    setIsMobile(window.innerWidth < 799);
  };

    const sendMailMessage = () => {
        const userIds: Array<number> = [];
        const memberIds: Array<number> = [];
        let checkCompany = false;
        channel.members.forEach((mem: any) => {
            if(mem.userId.startsWith('user')){
                userIds.push(Number(mem.userId.split('-')[1]))
            } else if(mem.userId.startsWith('member') && mem.userId !== client.member?.sendbirdUserId){
                memberIds.push(Number(mem.userId.split('-')[1]))
            } else if(mem.userId.startsWith('company')){
                checkCompany = true;
            }
        })
        postMailMessage({
            company_id: context.contractCompanyId,
            user_ids: userIds,
            member_ids: memberIds,
            site_emails: checkCompany ? context.email.trim().split(',') : [],
            site_allow_chat_mail_sending: context.siteAllowChatMailSending
        })
    }
    useEffect(() => {
        if(!context.isChat){
            return history.push(routes.chatOff.path);
        }
        fetchMemberDetail().then((res: TApiResult & TApiMemberDetailResult) => {
          if (res.isSuccess && res?.result) {
            const newMember: TAppMember = {
              name: `${res.result.sei}${res.result.mei}`,
              rankName: res.result.rank,
              rankImage: res.result.rank_image,
              totalPoint: res.result.current_period_point,
              email: res.result.email,
              sendbirdUserId: res.result.sendbird_user_id,
              sendbirdUserAccessToken: res.result.sendbird_user_access_token
            };
            client.setMember(newMember);
          }
        });
        window.addEventListener("resize", updateMedia);
        return () => {
            window.removeEventListener("resize", updateMedia);
        };
    }, []);



  return (
    <BaseHead
      title={"チャット"}
      login={true}
      subdomain={subdomain}
      hasBackground
      hasNavigationBar={true}
      showFooter={isMobile ? false : true}
    >
      {isMobile ? (
        <ChatMobile
          channel={channel}
          setChannel={setChannel}
          sendMailMessage={sendMailMessage}
        />
      ) : (
        <ChatPc
          channel={channel}
          setChannel={setChannel}
          sendMailMessage={sendMailMessage}
        />
      )}
      <BottomNavigationBar />
    </BaseHead>
  );
};

export default Chat;
