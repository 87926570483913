import {useHistory } from "react-router-dom";
import Image from "../atoms/Image";
import STMemberInfoCard from "../../assets/styles/organisms/memberInfoCard.module.scss";
import STUtils from "../../assets/styles/utility.module.scss";
import ButtonLink from "../atoms/ButtonLink";
import { TAppMember } from "../../types/TAppMember";
import TApiResult from "../../types/api/TApiResult";
import React, { useContext, useEffect, useState } from "react";
import {
  fetchMemberDetail,
  TApiMemberDetailResult,
} from "../../utils/api/fetchMemberDetail";
import { useAuth } from "../../hooks/useAuth";
import { routes } from "../../router/Router";
import { SiteContext } from "../../providers/SiteProvider";
import ArrowRightButtonWhite from "../atoms/icons/ArrowRightButtonWhite";
import ArrowRightButtonOrange from "../atoms/icons/ArrowRightButtonOrange";
import { TApiRanksResult, fetchRanks } from "../../utils/api/fetchRanks";
import { TRank } from "../../api/ranks";
import { ScratchSetting } from "../../types/popup";
import { LoginUserContext } from "../../providers/LoginUserProvider";

type Props = {
  inHeader: boolean;
}

const convertNumber = (num: Number) => {
  return num.toLocaleString();
}

const MemberInfoCardNew: React.FC<Props> = (props: Props) => {
  const member = useContext(LoginUserContext).member;
  const [nextRank,setNextRank] = useState<TRank | undefined>(); 
  const [currentRank, setCurrentRank] = useState<TRank | undefined>();
  const history = useHistory();

  useEffect(() => {
    if (member?.totalPoint !== undefined) {
      fetchRanks().then((res: TApiResult & TApiRanksResult) => {
        if (!res.isSuccess || !res.result) return history.push(routes.error.path);
        const ranksArray: TRank[] = Array.isArray(res.result) ? res.result : []; 
        let check = false;
        for (const rank of ranksArray) {
          if (rank.required_point > member.totalPoint) {
            check = true;
            setNextRank(rank);
            setCurrentRank(ranksArray[ranksArray.indexOf(rank) - 1]);
            break;
          }
        }
        if(!check){
          setNextRank(ranksArray[ranksArray.length - 1]);
          setCurrentRank(ranksArray[ranksArray.length - 1]);
        }
      });
    }
  },[history, member])


  const site = useContext(SiteContext);
  if (!member || !nextRank) return <></>;
  return (
    <section className={`${STMemberInfoCard.member_info_card} ${STMemberInfoCard.member_info_card_new} ${props.inHeader ? STMemberInfoCard.member_info_card_new_header : STMemberInfoCard.member_info_card_new_top}`}>
      <div className={STMemberInfoCard.member_info_card_wrap_new}>
        <div className={STUtils.dn_sp}>
          {/* <MemberBenefitInfo
            onClickScratchBanner={onClickScratchBanner}
            scratchSetting={scratchSetting! as ScratchSetting}
            setScratchSetting={setScratchSetting}
          /> */}
        </div>
        <div className={`${STMemberInfoCard.member_info_new} ${!props.inHeader ? STMemberInfoCard.member_info_new_pc : ''}`}>
          <div className={STMemberInfoCard.member_name_txt}>
            {member.name}&nbsp;<span>様</span>
          </div>
          <div className={STMemberInfoCard.point}>
            {convertNumber(member.totalPoint)}<span>&nbsp;pt</span>
          </div>
        </div>
        <div className={STMemberInfoCard.member_info_block}>
          <div className={`${STMemberInfoCard.member_point_infor} ${!props.inHeader ? STMemberInfoCard.member_point_infor_pc : ''}`}>
            <div className={STMemberInfoCard.member_point_medal_new}>
              <div className={STMemberInfoCard.member_point_medal_img_block_new}>
                <Image
                  imageUrl={member.rankImage}
                  alt={``}
                  className={STUtils.img_fit}
                />
              </div>
              <div className={STMemberInfoCard.member_point_medal_txt_new}>
                <h5>会員ランク</h5>
                {member.rankName}
              </div>
            </div>

            <div className={STMemberInfoCard.member_point_info_new}>
              <div className={STMemberInfoCard.member_point_new}>
                あと <span>{nextRank?.required_point && nextRank?.required_point > member.totalPoint ? convertNumber(nextRank?.required_point - member.totalPoint) : 0}</span> ptで
                来期想定ランク<h3>{nextRank?.label}</h3>
              </div>
              <div className={STMemberInfoCard.progress_bar}>
                <div className={STMemberInfoCard.progress_bar_done} style={{width: `${nextRank && currentRank && currentRank.required_point !== nextRank.required_point && nextRank.required_point > member.totalPoint ? ((member.totalPoint - currentRank.required_point)/(nextRank.required_point - currentRank.required_point))*100 : 100}%`}}></div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${STMemberInfoCard.button_block} ${!props.inHeader ? STMemberInfoCard.button_block_pc : ''}`}>
            <div className={STMemberInfoCard.button}>
              <a
                href={routes.pointRequest.path}
                className={`${STMemberInfoCard.button_active_link} ${STMemberInfoCard.button_common}`}
              >
                {site.isPoint ? "ポイント申請" : "リワード申請"}
              </a>
              {!props.inHeader && <ArrowRightButtonWhite/>}
            </div>
            <div className={STMemberInfoCard.button}>
              <a
                href={routes.pointHistory.path}
                className={`${STMemberInfoCard.button_inactive_link} ${STMemberInfoCard.button_common}`}
              >
                ポイント履歴
              </a>
              {!props.inHeader && <ArrowRightButtonOrange />}
            </div>
          </div>
      </div>
    </section>
  );
};
export default MemberInfoCardNew;