import { Link, useHistory } from "react-router-dom";
import Image from "../atoms/Image";
import STMemberInfoCard from "../../assets/styles/organisms/memberInfoCard.module.scss";
import STUtils from "../../assets/styles/utility.module.scss";
import ButtonLink from "../atoms/ButtonLink";
import STButtonLink from "../../assets/styles/atoms/buttonLink.module.scss";
import MemberBenefitInfo from "./MemberBenefitInfo";
import PAGE_CONFIG from "../../config/page";
import { TAppMember } from "../../types/TAppMember";
import TApiResult from "../../types/api/TApiResult";
import { useContext, useEffect, useState } from "react";
import {
  fetchMemberDetail,
  TApiMemberDetailResult,
} from "../../utils/api/fetchMemberDetail";
import { useAuth } from "../../hooks/useAuth";
import { routes } from "../../router/Router";
import { SiteContext } from "../../providers/SiteProvider";
import Button from "../../components/atoms/Button";
import { ScratchSetting } from "../../types/popup";
import { Box } from "@material-ui/core";
import { LoginUserContext } from "../../providers/LoginUserProvider";
type Props = {
  onClickScratchBanner: () => void;
  scratchSetting: ScratchSetting | undefined;
  setScratchSetting: (scratchSetting: ScratchSetting) => void;
};
const MemberInfoCard = ({
  onClickScratchBanner,
  scratchSetting,
  setScratchSetting,
}: Props) => {
  const member = useContext(LoginUserContext).member;
  const history = useHistory();
  const { logout } = useAuth();

  const handleClickLogout = () => {
    logout();
  };

  const site = useContext(SiteContext);
  if (!member) return <></>;
  return (
    <section className={STMemberInfoCard.member_info_card}>
      <div className={STMemberInfoCard.member_info_card_wrap}>
        <div className={STUtils.dn_sp}>
          <MemberBenefitInfo
            onClickScratchBanner={onClickScratchBanner}
            scratchSetting={scratchSetting! as ScratchSetting}
            setScratchSetting={setScratchSetting}
          />
        </div>
        <div className={STMemberInfoCard.member_info}>
          <div className={STMemberInfoCard.member_name_txt}>
            {member.name}&nbsp;様
          </div>

          <div className={STMemberInfoCard.member_name_info}>
            <Button
              buttonClassName={STMemberInfoCard.member_name_logout}
              text="ログアウト"
              clickAction={handleClickLogout}
            />
            <Box
              fontWeight={700}
              border={"1px solid #757575"}
              color={"#6C5D2F"}
              padding={1}
              borderRadius={8}
              ml={1}
            >
              <Link to={routes.mypage.path}>マイページ</Link>
            </Box>
          </div>
        </div>
        <div>
          <div className={STMemberInfoCard.member_point}>
            <div className={STMemberInfoCard.member_point_medal}>
              <div className={STMemberInfoCard.member_point_medal_img_block}>
                <Image
                  imageUrl={member.rankImage}
                  alt={`メンバーメダル ${member.rankName}`}
                  className={STUtils.img_fit}
                />
              </div>
              <div className={STMemberInfoCard.member_point_medal_txt}>
                {member.rankName}
              </div>
            </div>

            <div className={STMemberInfoCard.member_point_info}>
              {!site.isPoint && (
                <div className={STMemberInfoCard.member_point_info_label}>
                  Rewards score
                </div>
              )}
              <div
                className={
                  site.isPoint
                    ? `${STMemberInfoCard.member_point_info_txt} ${STMemberInfoCard.member_point_info_txt_point}`
                    : `${STMemberInfoCard.member_point_info_txt}`
                }
              >
                {member.totalPoint}
                {site.isPoint && (
                  <small className={STMemberInfoCard.member_point_info_suffix}>
                    &nbsp;pt
                  </small>
                )}
              </div>
            </div>
          </div>

          <ButtonLink
            url={routes.pointRequest.path}
            text={site.isPoint ? "ポイント申請" : "リワード申請"}
            buttonClassName={`${STButtonLink.primary} ${STMemberInfoCard.member_point_request_btn}`}
            linkClassName={STMemberInfoCard.member_point_request_link}
          />

          <Link
            to={routes.pointHistory.path}
            className={STMemberInfoCard.point_history_link}
          >
            {site.isPoint ? "ポイント履歴" : "リワード履歴"}&nbsp;&gt;
          </Link>
        </div>
      </div>
      <div className={STUtils.dn_pc}>
        <MemberBenefitInfo
          onClickScratchBanner={onClickScratchBanner}
          scratchSetting={scratchSetting! as ScratchSetting}
          setScratchSetting={setScratchSetting}
        />
      </div>
    </section>
  );
};
export default MemberInfoCard;
