import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import STBottomNav from "../../assets/styles/organisms/footer.module.scss";
import { UnreadMessageContext } from '../../providers/ChatProvider';
import { SiteContext } from '../../providers/SiteProvider';


type Props = {
    icon: React.ReactNode,
    text: string,
    url: string,
    index: number,
    activeIcon: React.ReactNode,
}

const BottomNavigationItem: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const {unreadCount} = useContext(UnreadMessageContext);
  const isChat = useContext(SiteContext).isChat;

  
  return (
    <NavLink to={props.url} exact className={isActive =>
        STBottomNav.nav_item + ' ' + (isActive && !searchParams.has('list') ? STBottomNav.nav_item_active : STBottomNav.nav_item_inactive)}>
        {props.icon}
        {props.activeIcon}
        <h5>{props.text}</h5>
        {props.index === 3 && unreadCount > 0 && isChat &&
          <span className={STBottomNav.chat_notification} ></span>
        }
    </NavLink>
  )
}

export default BottomNavigationItem;
