import { TPopupSettingResponse } from "../api/popup_setting";
const useSaveHistoryToLocalStorage = (): {
  saveInvisibleDate: (popup?: TPopupSettingResponse) => void;
  saveInvisibleUpdatedAt: (popupUpdatedAt?: string) => void;
} => {
  /**
   * ポップアップの表示日付をローカルストレージに保存
   *
   * @param key
   */
  const saveInvisibleDate = (popup?: TPopupSettingResponse): void => {
    const now = new Date();
    const Data = {
      saveDate: now, // ローカルストレージに日付を保存
      popupStartDate: popup?.display_start_date,
      popupEndDate: popup?.display_end_date,
      popupUpdatedAt: popup?.updated_at,
    };
    localStorage.setItem("oneDateInvisible", JSON.stringify(Data));
  };

  /**
   * ポップアップ設定情報の更新日付をローカルストレージ保存
   *
   * @param key
   * @param popupUpdatedAt
   */
  const saveInvisibleUpdatedAt = (popupUpdatedAt?: string): void => {
    const Data = {
      savePopupUpdatedAt: popupUpdatedAt,
    };
    localStorage.setItem("permanentInvisible", JSON.stringify(Data));
  };
  return {
    saveInvisibleDate,
    saveInvisibleUpdatedAt,
  };
};

export default useSaveHistoryToLocalStorage;
