import React from 'react';
import STComfirmFilesMessage from "../../assets/styles/organisms/comfirmFilesMessage.module.scss";
import Icon from '@sendbird/uikit-react/ui/Icon';

type PreviewFileProps = {
    fileMessageParams: any;
    handleRemoveFiles: any;
}

const PreviewFile = ({fileMessageParams, handleRemoveFiles} : PreviewFileProps) => {

    return (
        <div className={STComfirmFilesMessage.image_container}>
            <div>
                {fileMessageParams.file.type.startsWith('image') ?
                    <img className={STComfirmFilesMessage.image_preview} src={URL.createObjectURL(fileMessageParams.file)} alt='file' /> 
                : 
                    <div className={STComfirmFilesMessage.file_container}>
                        <Icon className={STComfirmFilesMessage.file_icon} type='FILE_DOCUMENT' fillColor='WHITE' />
                        <div>{fileMessageParams.file.name}</div>
                    </div>
                }
            </div>
            <div onClick={handleRemoveFiles} className={STComfirmFilesMessage.close_icon}>
                <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.70535 4.41003C5.53246 4.24305 5.30091 4.15066 5.06056 4.15274C4.82022 4.15483 4.59031 4.25124 4.42035 4.4212C4.25039 4.59115 4.15399 4.82106 4.1519 5.06141C4.14981 5.30176 4.2422 5.53331 4.40918 5.7062L9.70293 10.9999L4.40827 16.2937C4.32071 16.3783 4.25088 16.4794 4.20284 16.5912C4.1548 16.7031 4.12951 16.8234 4.12845 16.9451C4.12739 17.0668 4.15059 17.1875 4.19668 17.3002C4.24277 17.4128 4.31083 17.5152 4.3969 17.6012C4.48297 17.6873 4.58532 17.7554 4.69797 17.8014C4.81063 17.8475 4.93134 17.8707 5.05305 17.8697C5.17476 17.8686 5.29505 17.8433 5.40689 17.7953C5.51872 17.7472 5.61987 17.6774 5.70443 17.5899L10.9991 12.2961L16.2928 17.5899C16.4657 17.7568 16.6973 17.8492 16.9376 17.8471C17.178 17.8451 17.4079 17.7487 17.5778 17.5787C17.7478 17.4087 17.8442 17.1788 17.8463 16.9385C17.8484 16.6981 17.756 16.4666 17.589 16.2937L12.2953 10.9999L17.589 5.7062C17.756 5.53331 17.8484 5.30176 17.8463 5.06141C17.8442 4.82106 17.7478 4.59115 17.5778 4.4212C17.4079 4.25124 17.178 4.15483 16.9376 4.15274C16.6973 4.15066 16.4657 4.24305 16.2928 4.41003L10.9991 9.70378L5.70535 4.40911V4.41003Z" fill="#FFF"/>
                </svg>
            </div>
        </div>
    )
}

export default PreviewFile;
